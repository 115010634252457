import React, { useState } from "react";
import { Box, Button, OutlinedInput } from "@mui/material";
import { useCommentsContext } from "../CommentsList/CommentsProvider/CommentsProvider";
import { CommentsDto, ReplyDto } from "../../dtos";

type Props = {
  parentComment?:
    | (CommentsDto & { ancestorIds?: string[] })
    | (ReplyDto & { ancestorIds?: string[] });
  afterSubmit?: () => void;
};

export const CommentsField: React.FC<Props> = ({
  parentComment,
  afterSubmit,
}) => {
  const { addComment } = useCommentsContext();
  const [comment, setComment] = useState("");

  const handleSubmit = async () => {
    await addComment(
      comment,
      parentComment?._id,
      parentComment?.ancestorIds || []
    );
    setComment("");
    afterSubmit?.();
  };

  const isReply = !!parentComment?._id;

  const commentText = !!parentComment
    ? parentComment.user.fullName
    : "this comment";

  const placeholder = isReply
    ? `Reply to ${commentText}`
    : "Write a comment...";

  return (
    <Box display="flex" alignItems="center" gap={1} my={!isReply ? 2 : 0}>
      <Box flex={1}>
        <OutlinedInput
          fullWidth
          multiline
          // sx={{ "*": { color: "inherit" } }}
          value={comment}
          placeholder={placeholder}
          autoFocus={isReply}
          endAdornment={
            <Button
              sx={{ alignSelf: "flex-end" }}
              variant="contained"
              onClick={handleSubmit}
              disabled={!comment.trim().length}
            >
              Comment
            </Button>
          }
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
      </Box>
    </Box>
  );
};
