import { useMemo } from "react";
import { useAppData } from "../../providers/AppDataProvider";
import { NavigationsDto } from "../../dtos";

const selectPrimaryNavItems = (appData: any) => {
  return (
    appData?.navigations?.find((nav) => nav.slug === "app-header")?.links || []
  );
};

export function useNavItems(): NavigationsDto["links"] {
  const { appData } = useAppData();

  return useMemo(() => {
    return selectPrimaryNavItems(appData);
  }, [appData?.navigations]);
}
