/* eslint-disable jsx-a11y/alt-text */
import React, {
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import { useIntersectionObserver } from "../../hooks/useInteractionObserver";
import type { UploadsDto as MediaDto } from "../../dtos";
import styles from "./GalleryImage.module.scss";
import clsx from "clsx";
import { getImageProps, getSmallestFormat } from "../../utils/getImageProps";
import { useIsClient } from "../../hooks/useIsClient";

interface LazyGalleryImageProps extends HTMLProps<HTMLImageElement> {
  image: MediaDto;
  root?: HTMLElement;
  threshold?: number;
  lazy?: boolean;
  onVisibilityChange?: (entry: IntersectionObserverEntry) => void;
}

const hasIOSupport = !!global.IntersectionObserver;

export function LazyGalleryImage({
  image,
  className,
  onVisibilityChange,
  root,
  threshold,
  lazy,
  ...rest
}: LazyGalleryImageProps) {
  const isClient = useIsClient();
  const ref = useRef<HTMLImageElement>(null);
  const [hasError, setHasError] = useState(false);
  const hasLoaded = useRef(false);
  const entry = useIntersectionObserver(ref, {
    freezeOnceVisible: onVisibilityChange ? false : true,
    root,
    threshold,
  });

  const onError = useCallback(() => {
    setHasError(true);
  }, []);

  useEffect(() => {
    if (onVisibilityChange && entry) {
      onVisibilityChange(entry);
    }
  }, [entry, onVisibilityChange]);

  const imageProps = useMemo(() => {
    const fullImageProps = getImageProps(image);

    if (hasLoaded.current) {
      return fullImageProps;
    }

    if (!isClient) {
      return { src: getSmallestFormat(image), alt: fullImageProps.alt };
    }

    const props =
      entry?.isIntersecting || !hasIOSupport
        ? getImageProps(image)
        : { src: !lazy && getSmallestFormat(image), alt: fullImageProps.alt };

    if (entry?.isIntersecting) {
      hasLoaded.current = true;
    }

    return props;
  }, [entry?.isIntersecting, image, lazy, isClient]);

  if (hasError) {
    return (
      <div className={styles.noImage}>
        <NoPhotographyIcon />
        <p>NO IMAGE FOUND</p>
      </div>
    );
  }

  return (
    <img
      {...rest}
      {...imageProps}
      className={clsx(styles.galleryImage, className)}
      onError={onError}
      ref={ref}
    />
  );
}
