import loadable from "@loadable/component";
import Page from "./Page";
import MongooseModelGenerator from "./MongooseGenerator";
import FormBuilder from "../components/FormBuilder";
// import { MediaLibrary } from "../components/MediaLibrary/MediaLibrary";

const AboutPage = loadable(() => import("./AboutPage"));
const LoginPage = loadable(() => import("./LoginPage"));

export const APP_ROUTES = [
  { path: "/about", exact: true, component: AboutPage },
  { path: "/login", exact: true, component: LoginPage },
  { path: "/mongoose/:id?", exact: true, component: MongooseModelGenerator },
  { path: "/forms/:formSlug?", exact: true, component: FormBuilder },
  // { path: "/media", component: MediaLibrary },
  {
    path: ["/", "/:slug"],
    componentName: "PageBuilderPage",
    exact: true,
    component: Page,
  },
];
