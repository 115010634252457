import { ThemeProvider, createTheme } from "@mui/material";
import React, { FC } from "react";

const DARK_THEME = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: '"Montserrat", sans-serif',
  },
  palette: {
    mode: "dark",
    background: {
      default: "#222",
      paper: "#222",
    },
  },
});

export const DarkTheme: FC = ({ children }) => {
  return <ThemeProvider theme={DARK_THEME}>{children}</ThemeProvider>;
};
