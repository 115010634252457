import { Box, Container } from "@mui/material";
import React from "react";

export function AppFooter() {
  return (
    <Box id="APP_FOOTER" padding={1}>
      <Container maxWidth="lg">
        Copyright Henry Latour {new Date().getFullYear()}. All rights reserved
      </Container>
    </Box>
  );
}
