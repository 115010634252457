import React, {
  FC,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { DarkTheme } from "../DarkTheme/DarkTheme";
import { Box, Button, ClickAwayListener, Drawer } from "@mui/material";
import "./EditorDrawer.scss";
import { usePageEditor } from "../../PageStore/PageEditorProvider";
import { SavePageButton } from "../PageEditorToolbar/SavePageButton/SavePageButton";
import { editorUtils } from "./editorUtils";

type Props = {
  headerText?: string;
  saveText?: string;
  isOpen: boolean;
  onClose: () => void;
  onSaveComplete?: () => void;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  hideSaveBtn?: boolean;
  saveBtn?: ReactNode;
};

const EmptyComponent = () => null;

export const EditorDrawer: FC<Props> = ({
  isOpen,
  onClose,
  onCancel,
  onSaveComplete,
  saveText,
  headerText,
  children,
  hideSaveBtn,
  saveBtn,
}) => {
  const innerRef = useRef<HTMLDivElement>();

  const onTransitionEnd = (node, done) => {
    const width = node.offsetWidth;
    if (width > 250 && isOpen) {
      editorUtils.applyStyles(width);
    }
    done();
  };

  useEffect(() => {
    if (isOpen) {
      editorUtils.addRootStyles(innerRef.current?.offsetWidth);
    } else {
      editorUtils.removeRootStyles();
    }
    return () => {
      editorUtils.clearTimeouts();
    };
  }, [isOpen]);

  return (
    <DarkTheme>
      <Drawer
        classes={{
          root: "editor-drawer",
          paper: "editor-paper",
        }}
        components={{
          Backdrop: EmptyComponent,
        }}
        SlideProps={{
          addEndListener: onTransitionEnd,
          timeout: 100,
        }}
        disableScrollLock
        open={isOpen}
        onClose={onClose}
      >
        <Box
          data-editor-drawer="true"
          className="editor-wrapper"
          maxWidth={450}
          ref={innerRef}
        >
          <Box py={1} px={2} className="editor-header">
            <strong>{headerText}</strong>
          </Box>
          <Box py={1} px={2} className="editor-main">
            {children}
          </Box>
          <Box py={1} px={2} className="editor-footer">
            <Button size="small" onClick={onCancel || onClose}>
              Cancel
            </Button>
            {!hideSaveBtn && (
              <SavePageButton onSaveComplete={onSaveComplete}>
                {saveText}
              </SavePageButton>
            )}
            {saveBtn}
          </Box>
        </Box>
      </Drawer>
    </DarkTheme>
  );
};
