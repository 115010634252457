import { useState } from "react";
import { API } from "../client/API";

type BasePostBody = { _id?: string };

export const useSaveFunction = <T extends BasePostBody = any>(
  collection: string,
  params?: any
) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>();

  const handleCreate = (postBody: any): Promise<any> => {
    return API.post(`/${collection}`, postBody, params)
      .then((data) => {
        setIsSaving(false);
        setError(null);
        return data;
      })
      .catch((err) => {
        setIsSaving(false);
        setError(err);
        return postBody;
      });
  };

  const handleUpdate = (postBody: any): Promise<any> => {
    return API.put(`/${collection}/${postBody._id}`, postBody, params)
      .then((data) => {
        setIsSaving(false);
        setError(null);
        return data;
      })
      .catch((err) => {
        setIsSaving(false);
        setError(err);
        return postBody;
      });
  };

  const handleSave = async (postBody: T): Promise<T> => {
    if (!postBody) {
      throw new Error("the post body must be specified");
    }

    const isNew = !postBody._id;
    const saveFn = isNew ? handleCreate : handleUpdate;

    if (isSaving) {
      return postBody;
    }

    return await saveFn(postBody);
  };

  return { isSaving, error, handleSave };
};
