const getRootElement = () => {
  return document.getElementById("root") as HTMLDivElement;
};

const getAllDrawers = () => {
  const editors = document.querySelectorAll("div[data-editor-drawer]");
  return Array.from(editors).sort(
    (a: HTMLElement, b: HTMLElement) => a.offsetWidth - b.offsetWidth
  ) as HTMLDivElement[];
};

const MARGIN_AMOUNT = 8;

class EditorAnimations {
  rootEl: HTMLDivElement;
  isRemovingStyles: boolean;
  isAddingStyles: boolean;
  removeTimeout: NodeJS.Timeout;
  addTimeout: NodeJS.Timeout;

  constructor() {}

  initStyles() {
    this.isAddingStyles = true;
    this.addTimeout = setTimeout(() => {
      this.isAddingStyles = false;
    }, 250);
  }

  applyStyles(editorWidth: number) {
    if (!editorWidth) {
      return;
    }
    this.initStyles();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const offsetWidth = windowWidth - editorWidth;

    const scale = offsetWidth / windowWidth;

    const minHeight = Math.floor(windowHeight / scale);

    const rootEl = getRootElement();

    rootEl.style.setProperty("scale", scale.toString());

    rootEl.style.setProperty("min-height", `${minHeight}px`);
    rootEl.style.setProperty("height", `${minHeight}px`);

    rootEl.style.setProperty("margin-bottom", `${windowHeight - minHeight}px`);

    // rootEl.style.setProperty("margin", "8px 8px 8px 16px");

    // rootEl.style.setProperty("border-left", "16px solid #222");
    // rootEl.style.setProperty("border-right", "16px solid #222");

    rootEl.style.setProperty("transform-origin", "top right");

    rootEl.style.overflowY = "auto";
    rootEl.style.background = "#fff";

    document.body.style.height = `${windowHeight}px`;
    document.body.style.overflow = "hidden";
    document.body.style.background = "#222";
  }

  addRootStyles(editorWidth: number) {
    if (!editorWidth) {
      return;
    }

    this.addTimeout = setTimeout(() => {
      this.applyStyles(editorWidth);
    }, 0);
  }

  removeRootStyles() {
    const hasStyles = !!getRootElement().getAttribute("style");
    document.body.removeAttribute("style");

    if (hasStyles && !this.isAddingStyles) {
      const drawers = getAllDrawers();
      const [drawer] = drawers;
      getRootElement().removeAttribute("style");

      if (drawer) {
        setTimeout(() => {
          this.applyStyles(drawer.offsetWidth);
        }, 0);
      }
    }
  }

  clearTimeouts() {
    clearTimeout(this.addTimeout);
    // getRootElement().removeAttribute("style");
  }
}

export const editorUtils = new EditorAnimations();
