import Edit from "@mui/icons-material/Edit";
import Add from "@mui/icons-material/Add";
import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  ClickAwayListener,
  Drawer,
  IconButton,
} from "@mui/material";

import { API } from "../../../../../client/API";
import { useFormSchema } from "../../../../../widgets/hooks/useFormSchema";
import { PagesDto } from "../../../../../dtos";
import useToggle from "../../../../../hooks/useToggle";
import { createSlugFromString } from "../../../../../utils/createSlugFromString";
import { EditorDrawer } from "../../EditorDrawer/EditorDrawer";
import { useSaveFunction } from "../../../../../hooks/useSaveFunction";
import { useHistory } from "react-router-dom";
import { FormSchemaForm } from "../../../../../components/FormSchemaForm";

type Props = {
  initialValues?: PagesDto;
  onSaveComplete?: (values: any) => void;
};

const getButtonProps = (initialValues: any = {}): ButtonProps => {
  if (initialValues._id) {
    return {
      color: "primary",
      children: <Edit />,
    };
  }

  return {
    color: "primary",
    children: <Add />,
  };
};

const EmptyComponent = () => null;

export const EditPageDialog: FC<Props> = ({
  initialValues = {},
  onSaveComplete,
}) => {
  const history = useHistory();
  const [updatedValues, setUpdatedValues] = useState<PagesDto>(
    initialValues || ({} as any)
  );
  const [editorWidth, setEditorWidth] = useState<number>(0);
  const { isLoading, formSchema } = useFormSchema("page");
  const { isSaving, error, handleSave } = useSaveFunction("pages");

  const toggleProps = getButtonProps(initialValues);

  const [dialogOpen, toggleFormEditor] = useToggle(false);

  const headerText = updatedValues?._id ? "Edit page" : "Add page";

  const startIcon = isSaving ? (
    <CircularProgress size={16} color="inherit" />
  ) : undefined;

  const savePage = () => {
    const slug =
      updatedValues.slug || createSlugFromString(updatedValues.title);
    handleSave({ ...updatedValues, slug }).then((page) => {
      setUpdatedValues(page);
      history.push(`/${page.slug}`);
    });
  };

  return (
    <>
      <IconButton onClick={toggleFormEditor} {...toggleProps} />

      <EditorDrawer
        headerText={headerText}
        isOpen={dialogOpen}
        onClose={toggleFormEditor}
        hideSaveBtn
        saveBtn={
          <Button
            startIcon={startIcon}
            disabled={isSaving}
            variant="contained"
            onClick={savePage}
          >
            Save page
          </Button>
        }
      >
        <FormSchemaForm
          onChange={setUpdatedValues}
          form={formSchema as any}
          initialValues={initialValues}
        />
      </EditorDrawer>
    </>
  );
};
