import React, { Dispatch, SetStateAction } from "react";
import { CommentsDto, USER_ROLES } from "../../../dtos";
import { Box, Button, Paper, TextField } from "@mui/material";
import styles from "./CommentActions.module.scss";
import { CustomAvatar } from "../../Avatar/CustomAvatar";
import { ProtectedComponent } from "../../ProtectedComponent/ProtectedComponent";
import clsx from "clsx";
import { useCommentsContext } from "../CommentsProvider/CommentsProvider";
import { CommentsField } from "../../CommentsField/CommentsField";
import useToggle from "../../../hooks/useToggle";

type Props = {
  item: CommentsDto & { ancestorIds?: string[] };
  setComments: Dispatch<SetStateAction<CommentsDto[]>>;
};

const focusRefAndSelect = (textareaEl: HTMLTextAreaElement) => {
  if (textareaEl) {
    textareaEl.focus();
    textareaEl.setSelectionRange(
      textareaEl.value.length,
      textareaEl.value.length
    );
  }
};

export const CommentItem: React.FC<Props> = ({ item, setComments }) => {
  const [isEditMode, setEditMode] = React.useState(false);
  const { comment, createdAt, user, ancestorIds = [] } = item;
  const [isReplyOpen, toggleIsReplyOpen] = useToggle(false);
  const [editedComment, setEditedComment] = React.useState(comment);
  const { removeComment, updateComment } = useCommentsContext();

  const handleEditComplete = async () => {
    if (editedComment !== comment) {
      updateComment({ ...item, comment: editedComment }, ancestorIds);
      setEditMode(false);
    } else {
      setEditMode(false);
    }
  };

  const deleteComment = (commentId: string) => {
    removeComment(commentId, ancestorIds);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedComment(e.target.value);
  };

  const renderComment = () => {
    if (isEditMode) {
      return (
        <Box>
          <TextField
            value={editedComment}
            onChange={handleCommentChange}
            inputRef={focusRefAndSelect}
            autoFocus
            multiline
            fullWidth
          />
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Button
              className={styles.btn}
              variant="contained"
              onClick={handleEditComplete}
            >
              Save
            </Button>
            <Button
              className={styles.btn}
              onClick={() => {
                setEditMode(false);
                setEditedComment(comment);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      );
    }
    return (
      <Box fontSize={16} fontWeight={600}>
        {comment}
      </Box>
    );
  };

  return (
    <>
      <Paper sx={{ padding: 1.25, my: 1, ml: ancestorIds.length * 5 }}>
        <Box display="flex" flexWrap="wrap">
          <Box
            height={60}
            width={60}
            display="flex"
            pr={1.5}
            alignItems="center"
            justifyContent="center"
          >
            <CustomAvatar
              profilePicture={user?.profilePicture}
              fullName={user?.fullName}
            />
          </Box>
          <Box maxWidth="calc(100% - 60px)" flex={1}>
            <Paper
              variant="outlined"
              sx={{ p: 1.5, width: "100%", overflow: "hidden" }}
            >
              {renderComment()}
              <Box sx={{ color: "#aaa" }} mt={1} fontSize={12}>
                Posted by <b>{user.fullName}</b> on{" "}
                {new Date(createdAt).toLocaleString()}
              </Box>
              <Box>
                <ProtectedComponent
                  isLoginEnabled={false}
                  isRegisterEnabled={false}
                  initialUser={user}
                  roles={[USER_ROLES.USER]}
                >
                  <Button
                    color="inherit"
                    className={clsx(styles.btn, styles.inlineBtn)}
                    onClick={toggleIsReplyOpen}
                  >
                    Reply
                  </Button>
                </ProtectedComponent>
                <ProtectedComponent
                  isLoginEnabled={false}
                  isRegisterEnabled={false}
                  initialUser={user}
                  roles={[USER_ROLES.EDITOR]}
                >
                  <Button
                    className={clsx(styles.btn, styles.inlineBtn)}
                    onClick={() => setEditMode(true)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="error"
                    className={clsx(styles.btn, styles.inlineBtn)}
                    onClick={() => deleteComment(item._id)}
                  >
                    Delete
                  </Button>
                </ProtectedComponent>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Paper>
      {isReplyOpen && (
        <Paper sx={{ padding: 1.25, my: 1, ml: 5 }}>
          <CommentsField parentComment={item} afterSubmit={toggleIsReplyOpen} />
        </Paper>
      )}
    </>
  );
};
