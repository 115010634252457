import React, { Suspense, useState } from "react";
import loadable from "@loadable/component";
import { useIsAdmin } from "../../hooks/useIsAdmin";
import { PageElement } from "./PageElement/PageElement";
import { ReadOnlyProvider } from "./ReadOnlyContext/ReadOnlyContext";
import { PageCSS } from "./PageCSS/PageCSS";
import { usePageContext } from "./PagesProvider/PagesProvider";
import SiteMeta from "../../components/SiteMeta";
import { PagesDto } from "../../dtos";

const PageEditor = loadable(() => import("./PageEditor"));

type Props = {
  page: PagesDto;
};

const PageComponent: React.FC<Props> = ({ page: initialPage }) => {
  const isAdmin = useIsAdmin();
  const [page, setPage] = useState<PagesDto>(initialPage);

  const { elements = [] } = page;

  const pageMarkup = (
    <div id="PAGE">
      <PageCSS customCss={page.customCss} />
      <SiteMeta
        key={page._id}
        title={page.title}
        description={page.description}
        image={page.featuredImage}
      />
      {elements.map((element) => {
        return <PageElement key={element.id} element={element} />;
      })}
    </div>
  );

  if (isAdmin) {
    return (
      <>
        <PageCSS customCss={page.customCss} />
        <Suspense fallback={pageMarkup}>
          <SiteMeta
            key={page._id}
            title={page.title}
            description={page.description}
          />
          <ReadOnlyProvider>
            <PageEditor key={page.slug} page={page} setPage={setPage} />
          </ReadOnlyProvider>
        </Suspense>
      </>
    );
  }

  return <>{pageMarkup}</>;
};

export const Page: React.FC = () => {
  const { isLoading, error, page } = usePageContext();

  if (!page) {
    return null;
  }

  return <PageComponent key={page._id} page={page} />;
};

Page.displayName = "PageBuilderPage";
