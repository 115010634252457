import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { usePageEditor } from "../../../PageStore/PageEditorProvider";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import Save from "@mui/icons-material/Save";

type Props = {
  onSaveComplete?: () => void;
};

export const SavePageButton: React.FC<Props> = ({
  children,
  onSaveComplete,
}) => {
  const { savePage, saveStatus } = usePageEditor();

  const handleSave = async () => {
    await savePage();
    onSaveComplete?.();
  };

  const renderStartIcon = () => {
    switch (saveStatus) {
      case "LOADING":
        return <CircularProgress size={16} color="inherit" />;
      case "COMPLETE":
        return <CheckCircle />;
      case "ERROR":
        return <Error />;
      default:
        return <Save />;
    }
  };

  return (
    <Button
      startIcon={renderStartIcon()}
      color="primary"
      variant="contained"
      onClick={handleSave}
    >
      {children || "Save page"}
    </Button>
  );
};
