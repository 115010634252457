import React from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { AppHeader } from "./components/AppHeader/AppHeader";
import { AppRoutes } from "./components/AppRoutes/AppRoutes";
import "./App.css";
import "./styles.scss";
import { AppFooter } from "./components/AppFooter/AppFooter";

const APP_THEME = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#dfd4ae",
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: '"Montserrat", sans-serif',
    button: {
      textTransform: "none",
      fontWeight: 600,
    },
  },
});

const App = () => (
  <ThemeProvider theme={APP_THEME}>
    <AppHeader />
    <AppRoutes className="app-main" />
    <AppFooter />
    <CssBaseline />
  </ThemeProvider>
);

export default App;
