import { Box, Select } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDotySearchParams } from "../../hooks/useDotySearchParams";
import { findSelectedOption, SORT_OPTIONS, SortOption } from "./constants";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const useSelectedSort = () => {
  const { searchParams } = useDotySearchParams();
  const { sort } = searchParams;
  const currentSort = findSelectedOption(sort);

  const getApiSortParam = useCallback(() => {
    return { [currentSort.key]: currentSort.value };
  }, [currentSort]);

  return { currentSort, getApiSortParam };
};

export const DotySortDropdown: React.FC = () => {
  const { setParam } = useDotySearchParams();
  const { currentSort } = useSelectedSort();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (option: SortOption) => {
    setParam("sort", { [option.key]: option.value });
    handleClose();
  };

  return (
    <Box>
      <Button
        endIcon={<ArrowDropDownIcon />}
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
        color="inherit"
        sx={{ textTransform: "none", color: "#ccc" }}
      >
        {currentSort?.label || "Sort by"}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {SORT_OPTIONS.map((option) => {
          return (
            <MenuItem
              key={option.key}
              onClick={() => handleChange(option)}
              value={option.key}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
