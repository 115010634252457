import React, { useCallback, useEffect, useRef, useState } from "react";
import { WidgetDto } from "../types";
import { API } from "../../client/API";
import { Box, Grid } from "@mui/material";
import { DotyCard } from "./DotyCard";
import {
  DotySortDropdown,
  useSelectedSort,
} from "./components/DotySortDropdown/DotySortDropdown";
import { deepEquals } from "../../utils/deepEquals";
import { DotyListJsonLD } from "./components/DotyListJsonLD";

const useDotys = (initialData = []) => {
  const [dotys, setDotys] = useState(initialData);
  const { getApiSortParam } = useSelectedSort();
  const prevSort = useRef<Record<string, any>>({});

  const fetchDotys = useCallback(async (searchParams = {}) => {
    await API.get(`/dotycandidates`, searchParams).then(setDotys);
  }, []);

  const handleDotySaved = useCallback(
    (event: CustomEvent<any>) => {
      const dotySaved = event.detail;
      const foundDoty = dotys.some((doty) => doty._id === dotySaved?._id);
      if (foundDoty) {
        setDotys((prevDotys) =>
          prevDotys.map((doty) => {
            if (doty._id === dotySaved?._id) {
              return { ...dotySaved };
            }
            return doty;
          })
        );
      } else {
        fetchDotys({ sort: getApiSortParam() });
      }
    },
    [dotys, getApiSortParam]
  );

  useEffect(() => {
    window.addEventListener("DOTY_SAVED", handleDotySaved, false);

    return () => {
      window.removeEventListener("DOTY_SAVED", handleDotySaved, false);
    };
  }, [handleDotySaved]);

  useEffect(() => {
    const sort = getApiSortParam();
    if (!deepEquals(sort, prevSort.current)) {
      fetchDotys({ sort });
      prevSort.current = sort;
    }
  }, [fetchDotys, getApiSortParam]);

  return dotys;
};

export const DotyListPlugin: React.FC<WidgetDto> = (props) => {
  const { data, initialData = [] } = props;
  const dotys = useDotys(initialData);

  return (
    <Grid container spacing={2}>
      <DotyListJsonLD dotys={dotys} />
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <DotySortDropdown />
        </Box>
      </Grid>
      {dotys.map((doty) => {
        return (
          <Grid item xs={6} sm={6} md={4} key={doty._id}>
            <DotyCard doty={doty} />
          </Grid>
        );
      })}
    </Grid>
  );
};
