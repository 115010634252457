import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import useConstructibleRef from "../../../hooks/useConstructibleRef";
import { Switch } from "@mui/material";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useIsAdmin } from "../../../hooks/useIsAdmin";
import { EditPageDialog } from "../PageEditor/PageEditorToolbar/EditPageDialog/EditPageDialog";
import { PagesDto } from "../../../dtos";

const ReadOnlyContext = createContext<boolean>(true);

const AppHeaderPortal: FC = ({ children }) => {
  const APP_PORTAL = useConstructibleRef(() =>
    document.getElementById("PAGE_TOOLS")
  );

  if (!APP_PORTAL) {
    return null;
  }

  return createPortal(children, APP_PORTAL);
};

const ENTER_EDIT_MODE = "enter_edit_mode";
const LEAVE_EDIT_MODE = "leave_edit_mode";
const EDIT_MODE_EVENTS = [ENTER_EDIT_MODE, LEAVE_EDIT_MODE];

export const enterEditMode = () => {
  window.dispatchEvent(new CustomEvent(ENTER_EDIT_MODE));
};

export const leaveEditMode = () => {
  window.dispatchEvent(new CustomEvent(LEAVE_EDIT_MODE));
};

const setupWindowListeners = (setIsReadOnly: any) => {
  const handleEvt = (e: CustomEvent) => {
    setIsReadOnly(e.type === LEAVE_EDIT_MODE);
  };

  EDIT_MODE_EVENTS.forEach((evt) => {
    window.addEventListener(evt, handleEvt, false);
  });

  return () => {
    EDIT_MODE_EVENTS.forEach((evt) => {
      window.removeEventListener(evt, handleEvt, false);
    });
  };
};

export const ReadOnlyProvider: FC = ({ children }) => {
  const isAdmin = useIsAdmin();
  const [isReadOnly, setIsReadOnly] = useLocalStorage<boolean>(
    "EDIT_MODE",
    true
  );

  useEffect(() => {
    return setupWindowListeners(setIsReadOnly);
  }, []);

  const toggleReadOnly = () => setIsReadOnly(!isReadOnly);

  const isReadOnlyValue = useMemo(() => {
    if (!isAdmin) {
      return true;
    }
    return isReadOnly;
  }, [isReadOnly, isAdmin]);

  const onNewPageCreate = (page: PagesDto) => {
    window.location.pathname = `/${page.slug}`;
  };

  return (
    <ReadOnlyContext.Provider value={isReadOnlyValue}>
      {children}
      <AppHeaderPortal>
        <EditPageDialog onSaveComplete={onNewPageCreate} />
        <Switch checked={!isReadOnly} onClick={toggleReadOnly} />
      </AppHeaderPortal>
    </ReadOnlyContext.Provider>
  );
};

export const useIsReadOnly = () => useContext(ReadOnlyContext);
