import React from "react";
import { CommentItem } from "./CommentItem/CommentItem";
import {
  CommentsContext,
  useCommentsProvider,
} from "./CommentsProvider/CommentsProvider";
import { ProtectedComponent } from "../ProtectedComponent/ProtectedComponent";
import { CommentsField } from "../CommentsField/CommentsField";

type Props = {
  referenceId: string;
  numberOfComments: number;
};

export const CommentsList: React.FC<Props> = ({
  referenceId,
  numberOfComments,
}) => {
  const context = useCommentsProvider({
    referenceId,
    onModel: "DotyCandidates",
  });

  return (
    <CommentsContext.Provider value={context}>
      <ProtectedComponent
        isLoginEnabled
        isRegisterEnabled
        loginMessage="You must be logged in to add a comment."
      >
        <CommentsField />
      </ProtectedComponent>
      {context.flattenedComments.map((comment: any) => {
        return (
          <CommentItem
            key={comment._id}
            item={comment}
            setComments={context.setComments}
          />
        );
      })}
    </CommentsContext.Provider>
  );
};
